@mixin under576 {
  @media (max-width: 575px) { @content; }
}

@mixin under768 {
  @media (max-width: 767px) { @content; }
}

@mixin under992 {
  @media (max-width: 991px) { @content; }
}

@mixin under1200 {
  @media (max-width: 1199px) { @content; }
}

@mixin underContainerMedium {
  @media (max-width: 1450px) { @content; }
}

@mixin underContainerBig {
  @media (max-width: 1660px) { @content; }
}