@font-face {
  font-family: 'ralewaymedium';
  src: url('../raleway-medium-webfont.woff2') format('woff2'),
        url('../raleway-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'ralewaybold';
  src: url('../raleway-bold-webfont.woff2') format('woff2'),
        url('../raleway-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'ralewaylight';
  src: url('../raleway-light-webfont.woff2') format('woff2'),
  url('../raleway-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}