$brown: #ab9979;
$blue: #243267;

$colorBlack: #0c2134;

$colorRed800: #ff3e3e;

$colorBlue400: #8db9e5;

$colorBrown400: #C0A68F;

$colorGray100: #E3E3E3;
$colorGray500: #706d6e;

@mixin letter-spacing($letter-spacing) {
  letter-spacing: ($letter-spacing / 1000) * 1em;
}

@mixin boxshadow($x, $y, $b, $s, $color) {
  box-shadow: $x $y $b $s $color;
  -webkit-box-shadow: $x $y $b $s $color;
  -moz-box-shadow: $x $y $b $s $color;
}

@mixin bottomDashBorder($color, $size, $secondSize: 1px) {
  background-image: linear-gradient(
    to right, rgba(255, 255, 255, 0) 50%,
    $color 0%
  );
  background-position: bottom;
  background-size: $size $secondSize;
  background-repeat: repeat-x;
}

@mixin bgColorOpacity($color, $opacity: 1) {
  background-color: rgba($color, $opacity);
}

@mixin colorOpacity($color, $opacity: 1) {
  color: rgba($color, $opacity);
}

