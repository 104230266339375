@font-face {
  font-family: "Helvetica";
  src: url("../helvetica.woff2") format("woff2"),
    url("../helvetica.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica";
  src: url("../helvetica_oblique.woff2") format("woff2"),
    url("../helvetica_oblique.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica";
  src: url("../helvetica_bold.woff2") format("woff2"),
    url("../helvetica_bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica";
  src: url("../helvetica_bold_oblique.woff2") format("woff2"),
    url("../helvetica_bold_oblique.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica";
  src: url("../helvetica_light.woff2") format("woff2"),
    url("../helvetica_light.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica";
  src: url("../helvetica_light_oblique.woff2") format("woff2"),
    url("../helvetica_light_oblique.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}
