header {
  font-family: 'ralewaymedium', sans-serif;
  display: inline-block;
  color: #990000 !important;
  position: fixed;
  top: 14rem;
  right: 1rem;
  z-index: 1000;
  transition: top ease .5s;

  .container-custom-big {
    width: max-content;
    float: right;
    display: inline-block;
  }

  &.scroll {
    top: 3rem;
  }

  #desktop-menu-holder {
    background-color: #fff;
    max-width: 100%;
    transition: width ease-in .5s, padding-right ease-in .5s;
    height: 58px;
    width: 1050px;
    overflow: hidden;
    float: right;
    margin-right: 15px;
    text-align: right;
    padding: 13px 26px 13px 0;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 31px -11px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 0px 31px -11px rgba(0,0,0,0.55);
    box-shadow: 0px 0px 31px -11px rgba(0,0,0,0.55);
    position: relative;
    padding-right: 100px;

    &.closed {
      width: 75px;
      padding-right: 75px;

      nav {
        opacity: 0;
        transition: opacity ease-in .5s;
      }

      .burger-container {
        #desktop-menu-button {
          margin-left: 0;
          opacity: 1;
        }
      }
    }

    nav {
      padding-right: 64px;
      border-right: 1px solid $blue;
      padding-bottom: 1px;
      display: inline-block;
      z-index: 1;
      position: relative;
      transition: opacity ease-in .5s;
      opacity: 1;
      vertical-align: top;

      .nav-links {
        color: #686058;
        font-size: 20px;
        white-space: nowrap;

        a {
          color: #686058;
          text-decoration: none;

          &.active {
            font-family: 'ralewaybold', sans-serif;
          }
        }

        span {
          color: #686058;
          padding: 0 21px;
        }
      }
    }
  }

  #desktop-contact-btn {
    background-color: $blue;
    color: $brown;
    border-radius: 5px;
    text-align: center;
    width: 29rem;
    height: 58px;
    display: inline-block;
    float: right;
    font-size: 2rem;
    text-transform: uppercase;
    text-decoration: underline;
    padding-top: 12px;
    margin-right: 75px;
    opacity: 1;
    transition: opacity ease-in .5s .6s;

    img {
      display: inline-block;
      margin-right: 6px;
    }
    &.closed {
      opacity: 0;
      transition: opacity ease-in 0s 0s !important;
    }
  }

  #mobile-menu-button {
    display: inline-block;
    vertical-align: top;
    top: 13px;
    right: 0;
    position: relative;
  }

  #mobile-menu-holder {
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    left: 100%;
    height: 100%;
    background: #fff;
    transition: left ease-in-out .5s;

    &.opened {
      left: 0;
      transition: left ease-out .5s;
    }

    .nav-links {
      text-align: center;
      padding-top: 10vh;

      a {
        color: #686058;
        display: block;
        font-size: 3rem;


        &.active {
          font-family: 'ralewaybold', sans-serif;
        }
      }
    }

    .logo-swipe {
      margin-top: 1rem;
      display: inline-block;
      img {
        max-width: 100px;
      }
    }
  }

  #mobile-contact-btn {
    background-color: $blue;
    color: $brown;
    border-radius: 5px;
    text-align: center;
    width: 29rem;
    height: 58px;
    display: inline-block;
    //font-size: 2rem;
    text-transform: uppercase;
    text-decoration: underline;
    padding-top: 12px;
    padding-left: 15px;;
    padding-right: 15px;
    font-size: 2.5rem !important;
    margin: 0 auto 10px;

    span {
      color: #fff !important;
    }
    img {
      display: inline-block;
      margin-right: 6px;
    }
  }

  .burger-container {
    background-color: #fff;
    position: absolute;
    top: 11px;
    right: 18px;
    width: 38px;
    height: 36px;
    padding: 5px;
    justify-content: center;
    transition: opacity .3s linear;
    display: inline-block;

    & > div {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      &::after {
        content: "";
        display: inline-block;
        height: 80px;
        width: 80px;
        left: -18px;
        top: -20px;
        position: absolute;
        background-color: #fff;
        z-index: -1;
      }
    }

    #desktop-menu-button {
      display: inline-block;
      vertical-align: top;
      top: 0;
      right: 0;
      position: absolute;
      background: #fff;
      z-index: 10;
      opacity: 0;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }

    .nav-icon2 {
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      opacity: 0;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 50%;
        background: #243267;
        opacity: 1;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        &:nth-child(even) {
          left: 50%;
          border-radius: 0 9px 9px 0;
        }
        &:nth-child(odd) {
          left: 0px;
          border-radius: 9px 0 0 9px;
        }
        &:nth-child(1), &:nth-child(2) {
          top: 0px;
        }
        &:nth-child(3), &:nth-child(4) {
          top: 12px;
        }
        &:nth-child(5), &:nth-child(6) {
          top: 24px;
        }
      }

      &.open {
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        opacity: 1;

        span:nth-child(1), span:nth-child(6) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        span:nth-child(2), span:nth-child(5) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        span:nth-child(1) {
          left: 2px;
          top: 8px;
        }
        span:nth-child(2) {
          left: calc(50% - 2px);
          top: 8px;
        }
        span:nth-child(3) {
          left: -50%;
          opacity: 0;
        }
        span:nth-child(4) {
          left: 100%;
          opacity: 0;
        }
        span:nth-child(5) {
          left: 2px;
          top: 18px;
        }
        span:nth-child(6) {
          left: calc(50% - 2px);
          top: 18px;
        }
      }
    }
  }
}

@media (max-width: 1660px) {
  header {
    #desktop-menu-holder {
      margin-right: 0;
      padding-right: 70px;
      width: 945px;

      &.closed {
        width: 70px;
      }
      nav {
        padding-right: 25px;

        .nav-links span {
          padding: 0 18px;
        }
      }
    }

    #desktop-contact-btn {
      margin-right: 35px;
    }
  }
}

@media (max-width: 1450px) {
  header {
    #desktop-menu-holder {
      padding-right: 50px;
      width: 715px;

      &.closed {
        width: 50px;
      }

      nav {
        padding-right: 15px;
        margin-right: 20px;

        .nav-links {
          a {
            font-size: 1.7rem;
          }
          span {
            padding: 0 2px;
          }
        }
      }
    }

    #desktop-contact-btn {
      margin-right: 20px;
      font-size: 1.7rem;
      padding-top: 17px;
    }
  }
}

@media (max-width: 1200px) {
  header {
    #desktop-contact-btn {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
      span {
        display: none;
      }
      img {
        margin-left: 6px;
      }
    }
  }
}

@media (max-width: 991px) {
  header {
    top: 2rem;
    width: 75px;
    right: 0rem;
    .burger-container {
      border-radius: 3px;
      background: #fff;
      -webkit-box-shadow: 0px 0px 31px -11px rgba(0,0,0,0.55);
      -moz-box-shadow: 0px 0px 31px -11px rgba(0,0,0,0.55);
      box-shadow: 0px 0px 31px -11px rgba(0,0,0,0.55);
      z-index: 2000;
      text-align: center;
      width: 76px;
      height: 58px;
      padding-top: 16px;


      &.closed {
        #mobile-menu-button {
          opacity: 1;
        }
        .nav-icon2 {
          opacity: 0;
        }
      }

      & > div {
        &::after {
          display: none !important;
        }
      }

      .nav-icon2 {
        opacity: 1;
        position: relative;
        width: 30px;
        left: 18px;
      }

      #mobile-menu-button {
        top: 1px;
        right: 19px;
        position: absolute;
        opacity: 0;
        z-index: 100;
      }

    }

    #mobile-contact-btn {
      padding-top: 17px;
      font-size: 2rem !important;
      width: 30rem;
    }



  }
}

@media (max-width: 767px) {}

@media (max-width: 575px) {
  header {
    top: 2rem;
  }
}
