footer {
  overflow: hidden;
  background-color: $blue;
  padding-top: 6rem;
  padding-bottom: 7.5rem;
  position: relative;
  font-family: 'ralewaymedium', sans-serif;

  & * {
    vertical-align: top;
  }

  @include under992 {
    padding-top: 4em;
    padding-bottom: 3rem;
  }

  .first-line {
    position: relative;
    z-index: 30;

    .nav-links {
      padding-bottom: 5rem;
      display: inline-block;
      margin: auto;
      color: #fff;
      font-size: 2rem;
      @include under992 {
        padding-bottom: 3rem;
      }

      a {
        color: #fff;
        font-size: 2rem;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }

        &.active {
          font-family: 'ralewaybold', sans-serif;
        }
        @include under768 {
          display: block;
          text-align: center;
          margin-bottom: 1rem;
        }
      }

      span {
        color: #fff;
        font-size: 2rem;
        display: inline-block;
        padding: 0 8px 0 5px;

        @include under768 {
          display: none;
        }
      }
    }
  }

  .second-line {
    position: relative;
    z-index: 20;

    .left-side {
      @include underContainerMedium {
        display: block;
        text-align: center !important;
      }

      #contact-btn {
        padding-top: 20px;
        color: #fff;
        width: 30rem;
        height: 7rem;
        margin-right: 2.5rem;
        border-radius: 5px;
        background-color: $brown;
        display: inline-block;
        font-size: 2rem;
        text-align: center;
        text-transform: uppercase;
        text-decoration: underline;
        position: relative;
        z-index: 30;
        @include underContainerMedium {
          width: 28rem;
          margin-right: 2rem;
        }
        @include under1200 {
          height: 6rem;
          margin-right: 1rem;
          font-size: 1.6rem;
          width: 20rem;
          margin-bottom: 2rem;
        }
        @include under992 {
          margin-left: 1rem;
          margin-bottom: 2rem;
        }
        @include under768 {
          padding-top: 14px;
        }

        .icon-container {
          position: relative;
          display: inline-block;
          height: 25px;
          width: 25px;
          margin-right: 1rem;

          @include under768 {
            width: 20px;
            height: 20px;
          }

          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: opacity ease .3s;
            display: inline-block;
            &.always-show {
              opacity: 1;
            }
            &.hover-show {
              opacity: 0;
            }
          }
        }

        &:hover {
          color: $brown;
          background-color: #fff;
          text-decoration-color: $brown;

          .icon-container {
            img {
              &.always-show {
                opacity: 0;
              }
              &.hover-show {
                opacity: 1;
              }
            }
          }
        }
      }

      #phone-btn {
        padding-top: 20px;
        color: $blue;
        width: 25rem;
        height: 7rem;
        border-radius: 5px;
        background-color: #fff;
        display: inline-block;
        font-size: 2rem;
        text-align: center;
        position: relative;
        z-index: 20;
        border: 1px solid #fff;
        margin-right: 2.5rem;

        &::after{
          content: "";
          display: block;
          position: absolute;
          width: 83rem;
          height: 80rem;
          border: 1px solid #4d5984;
          transform: rotateZ(20deg);
          left: -50rem;
          top: -14rem;
          z-index: 0;
          pointer-events:none;
        }
        @include underContainerMedium {
          width: 22rem;
          margin-right: 1.8rem;
        }
        @include under1200 {
          height: 6rem;
          margin-right: 1rem;
          font-size: 1.6rem;
          width: 20rem;
        }
        @include under992 {
          margin-left: 1rem;
          margin-bottom: 2rem;
        }
        @include under768 {
          padding-top: 14px;
        }

        .icon-container {
          position: relative;
          display: inline-block;
          height: 25px;
          width: 25px;
          margin-right: 1rem;
          @include under768 {
            width: 20px;
            height: 20px;
          }

          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: opacity ease .3s;
            display: inline-block;
            &.always-show {
              opacity: 1;
            }
            &.hover-show {
              opacity: 0;
            }
          }
        }

        &:hover {
          text-decoration: none;
          color: #fff;
          background-color: $blue;
          border: 1px solid #fff;

          .icon-container {
            img {
              &.always-show {
                opacity: 0;
              }
              &.hover-show {
                opacity: 1;
              }
            }
          }
        }
      }

      #footer-brochure-btn {
        padding-top: 20px;
        color: #fff;
        width: 22rem;
        height: 7rem;
        border-radius: 5px;
        background-color: $blue;
        display: inline-block;
        font-size: 2rem;
        text-align: center;
        position: relative;
        z-index: 20;
        border: 1px solid #fff;

        @include underContainerMedium {
          width: 20rem;
        }
        @include under1200 {
          height: 6rem;
          margin-right: 1rem;
          font-size: 1.6rem;
          width: 20rem;
        }
        @include under992 {
          margin-left: 1rem;
          margin-bottom: 2rem;
        }
        @include under768 {
          padding-top: 14px;
        }

        .icon-container {
          position: relative;
          display: inline-block;
          height: 25px;
          width: 25px;
          margin-right: 1rem;
          @include under768 {
            width: 20px;
            height: 20px;
          }

          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: opacity ease .3s;
            display: inline-block;
            &.always-show {
              opacity: 1;
            }
            &.hover-show {
              opacity: 0;
            }
          }
        }

        &:hover {
          text-decoration: none;
          color: $blue;
          background-color: #fff;
          border: 1px solid $blue;

          .icon-container {
            img {
              &.always-show {
                opacity: 0;
              }
              &.hover-show {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .right-side {
      @include under1200 {
        text-align: center !important;
      }

      address {
        text-align: right;
        font-size: 2rem;
        color: #fff;
        padding-right: 45px;
        display: inline-block;
        @include under992 {
          display: block;
          text-align: center !important;
          padding: 0;
        }
      }

      #footer-logo {
        display: inline-block;
        position: relative;
        @include under992 {
          display: block;
          text-align: center !important;
        }
      }
    }
  }

  .third-line {
    position: relative;
    z-index: 30;

    .mentions {
      margin-top: 5.5rem;
      text-align: center;
      display: block;
      width: 100%;

      a {
        font-size: 1.6rem;
        color: #fff;
        text-decoration: underline;
      }
      @include under992 {
        margin-top: 2rem;
      }
    }
  }
}

#contact-popup {
  font-family: 'ralewaymedium', sans-serif;
  background-color: rgba(0,0,0, .5);
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: none;

  #brown-box {
    margin: 0 auto;
    margin-top: 10vh;
    position: relative;
    background-color: $brown;
    padding-top: 9.5rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 9rem;
    display: inline-block;
    text-align: left;
    @include under1200{
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
    @include under992{
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
    @include under576 {
      padding-top: 5rem;
      margin-top: 3vh;
      width: calc(100% - 2rem);
      margin-left: 1rem;
      margin-right: 1rem;
    }

    #closing-x {
      position: absolute;
      color: white;
      right: 3rem;
      top: 3rem;
      cursor: pointer;
      @include under1200{
        right: 2.5rem;
        top: 2.5rem;
        max-width: 35px;
        display: inline-block;
        img {
          max-width: 100%;
        }
      }

      @include under576 {
        right: 1rem;
        top: 1rem;
        max-width: 30px;
      }
    }

    h3 {
      font-size: 1.8rem;
      margin-bottom: 4.5rem;
      text-align: center;
      text-transform: uppercase;
      color: #fff;

      @include under992{
        margin-bottom: 2.5rem;
      }
      @include under576 {
        font-size: 1.6rem;
        margin-bottom: 2rem;
      }
    }

    form {
      padding-left: 30rem;
      padding-right: 32rem;

      @include under1200{
        padding-left: 20rem;
        padding-right: 20rem;
      }
      @include under992{
        padding-left: 10rem;
        padding-right: 10rem;
      }
      @include under576 {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      input[type="text"],input[type="email"] {
        height: 5rem;
        font-size: 1.5rem;
        color: $blue;
        background-color: #fff;
        margin-bottom: 2rem;
        width: 53rem;
        padding-left: 2rem;
        max-width: 100%;
        @include under992{
          height: 3.5rem;
        }
      }

      #pre-checkbox-text {
        font-size: 1.5rem;
        margin-bottom: 8px;
        color: #fff;
        width: 120%;
        @include under576 {
          width: 100%;
        }
      }

      input[type="checkbox"] {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }

      input[type="checkbox"] + label {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        color: #fff;
        position: relative;
        padding-left: 20px;
        width: 120%;
        @include under576 {
          width: 100%;
        }

        &:before {
          content: "";
          display: block;
          height: 13px;
          width: 13px;
          background-color: #fff;
          position: absolute;
          top: 4px;
          left: 0;
        }
      }

      input[type="checkbox"]:checked + label::before {
        background-position: center;
        background-size: 95%;
        background-repeat: no-repeat;
        background-image: url(/images/check-bleu.png);
      }

      button {
        text-align: center;
        margin: 35px auto 45px;
        border: 2px solid #fff;
        height: 9rem;
        width: 36rem;
        max-width: 90%;
        background-color: $brown;
        color: #fff;
        font-size: 2rem;
        text-transform: uppercase;
        display: block;
        @include under1200{
          height: 8rem;
          width: 32rem;
        }
        @include under992{
          margin: 15px auto 20px;
        }
        @include under576 {
          margin: 0px auto 10px;
        }
      }
    }

    .obligatoires {
      padding-left: 24rem;
      color: #fff;
      font-size: 1.5rem;

      @include under1200{
        padding-left: 17rem;
      }
      @include under992{
        padding-left: 7rem;
      }
      @include under576 {
        padding-left: 1rem;
      }

      a {
        color: #fff;
        font-size: 1.5rem;
      }
    }
  }

  #js-message-sent {
    display: none;
    color: #fff;
    text-align: center;
    font-size: 2rem;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}