@font-face {
  font-family: 'butlermedium';
  src: url('../butler_medium-webfont.woff2') format('woff2'),
  url('../butler_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'butlerbold';
  src: url('../butler_bold-webfont.woff2') format('woff2'),
  url('../butler_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}