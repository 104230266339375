.header-pal {
  position: absolute;
  z-index: 1;
  width: 100%;
  padding-top: 30px;
  font-family: Helvetica, Arial, sans-serif;
  .container-pal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .logo {
      min-width: 300px;
    }
    .label {
      text-align: center;
      font-size: 25px;
      line-height: 30px;
      font-weight: 100;
      letter-spacing: 1.6px;
      padding-right: 8px;
      b {
        font-weight: 700;
      }
    }
    .right-part {
      min-width: 300px;
      .villiers {
        float: right;
        height: 50px;
        line-height: 50px;
        color: $colorGray500;
        font-weight: bold;
        font-size: 30px;
        padding-left: 31px;
        border-left: 1px solid $colorBlue400;
        padding-right: 13px;
      }
    }
  }
  .upper {
    text-transform: uppercase;
  }
}

.with-header-pal {
  padding-top: 205px;
}
