@import 'mixins';
html {
  font-size: 10px;
  overflow-x: hidden;
  font-family: 'ralewaymedium', sans-serif;

  @include under768 {
    font-size: 8px;
  };

  body {
    overflow-x: hidden;
    max-width: 100%;
  }
}

img {
  max-width: 100%;
}

.container-pal {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
}

.cookiebar-content-hidden {
  display: none;
}

.upper {
  text-transform: uppercase;
}

.select2-dropdown {
  font-size: 12px;
  font-family: 'Helvetica';
  .select2-search__field {
    &:focus {
      outline: none;
    }
  }
}

.container-custom-medium {
  width: 1420px;
  max-width: 100%;
  margin: 0 auto;

  .row {
    width: 100%;
    margin: 0;
  }
  @include underContainerMedium {
    padding-right: 15px;
    padding-left: 15px;
  };
}

.container-custom-big {
  width: 1660px;
  max-width: 100%;
  margin: 0 auto;

  @include underContainerBig {
    padding-right: 15px;
    padding-left: 15px;
  };

}

.brown-text {
  color: $brown;
}

.brown-bg {
  background-color: $brown;
}

.blue-text {
  color: $blue;
}

.blue-bg {
  background-color: $blue;
}

.brown-text {
  color: $brown;
}

.brown-bg {
  background-color: $brown;
}

.white-text {
  color: #fff;
}

.wrapper {


  .container {

    @include under576 {
      padding-right: 15px;
      padding-left: 15px;
    };

    .row {
      @include under576 {
        margin-right: 0;
        margin-left:  0;
      };
    }
  }
}


.wrapper.top-padding {
  padding-top: 11rem;

  @include under576 {
    padding-top: 1rem;
  }
}

.for-white-logo, .for-brown-logo {
  margin-bottom: 7rem;
  a {
    display: inline-block;
    position: relative;

    img {
      @include under576 {
        max-width: 100px;
        margin-bottom: 2rem;
      }
    }
  }

  @include under576 {
    padding: 0;
    margin: 0;
  }
}

#title-container {
  border-left: 2px solid #fff;
  overflow: hidden;

  h1 {
    margin-top: 3.5rem;
    margin-bottom: 2rem;

    font-size: 9rem;
    color: #fff;
    text-transform: uppercase;
    margin-left: -1rem;
    font-family: "butlermedium", serif;

    @include under576 {
      font-size: 5.9rem;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    };


    span {
      font-size: 9rem;
      text-transform: uppercase;

      @include under576 {
        font-size: 5.9rem;
        margin-top: 2rem;
        margin-bottom: 1.5rem;
      };
    }
  }
}

.hovering:hover {
  -webkit-transition: background-color 250ms ease;
  -moz-transition: background-color 250ms ease;
  -ms-transition: background-color 250ms ease;
  -o-transition: background-color 250ms ease;
  transition: background-color 250ms ease;
  text-decoration: none !important;
}

.image-container {
  position: relative;

  .share-icon {
    position: absolute;
    top: 25px;
    right: 30px;


    @include under576 {
      width: 25px;
    }
  }

  .sharing-container {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    background-color: rgba(0,0,0, .8);
    left: 0;
    display: none;
    z-index: 50;
    .js-close-sharing-container {
      position: absolute;
      top: 2rem;
      right: 3rem;
      font-size: 3rem;
      color: #fff;
      cursor: pointer;
      z-index: 100;
    }

    & > div {
      width: 100%;
      height: 100%;
      padding-top: 100px;
      position: relative;

      .st-custom-button {
        color: #fff;
        display: block;
        margin: 0 auto;
        width: 50%;
        text-align: left;
        margin-bottom: 10px;
        font-size: 18px;
        font-family: 'ralewaymedium', sans-serif;
        cursor: pointer;

        img {
          display: inline-block;
          width: 25px;
          margin-right: 5px;
          margin-top: -8px;
        }
      }
    }
  }
}

.error404 {
  padding: 30vh 15px;
  text-align: center;

  h2 {
    color: $blue;
    font-size: 4rem;
    font-family: 'ralewaybold', sans-serif;
  }

  p, a {
    color: $blue;
    font-size: 2rem;
    font-family: 'ralewaymedium', sans-serif;
  }

  a {
    text-decoration: underline;
  }
}

input:required {
  box-shadow:none !important;
}

//input:invalid {
//  box-shadow:0 0 3px red  !important;
//}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}

@-webkit-keyframes scale-up-center2 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
}
@keyframes scale-up-center2 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
}
